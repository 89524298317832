export class AuthResponse<T> {
  type: AuthResponseType;
  data: T;

  constructor(type: AuthResponseType, data: T) {
    this.type = type;
    this.data = data;
  }
}

export enum AuthResponseType {
  MFA_REQUIRED = 'MFA_REQUIRED',
  MFA_CODE_INVALID = 'MFA_CODE_INVALID',
  MFA_CODE_MAX_ATTEMPTS = 'MFA_CODE_MAX_ATTEMPTS',
  MFA_CODE_GENERATION_ERROR = 'MFA_CODE_GENERATION_ERROR',
  INVALID_CAPTCHA = 'INVALID_CAPTCHA',
  SUCCESS = 'SUCCESS',
}
