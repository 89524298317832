import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable, share, Subject, switchMap, tap} from 'rxjs';
import {Role, User} from './dto/user';
import {environment} from '../../environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import {AuthResponse, AuthResponseType} from './dto/auth-res';
import {MfaStatus} from '../modules/teammate/contact-profile/ui/mfa-block-status/mfa.status';
import {ResetMfaTriesReq} from '../modules/teammate/contact-profile/ui/mfa-block-status/reset-mfa-tries.req';
import {jwtDecode} from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  API_BASE = `${environment.api}`;
  static readonly TOKEN_STORAGE_KEY = 'id_token';
  private _updateLoggedUser$ = new Subject<void>();
  loadedUser: User | undefined;

  constructor(private http: HttpClient) {
    this._updateLoggedUser$
      .pipe(switchMap(() => this.loadUserData()))
      .subscribe((user) => {
        this.loadedUser = user;
      });
  }

  public updateLoggedUser() {
    this._updateLoggedUser$.next();
  }

  login(
    username: string,
    password: string,
    cfToken: string,
    pin?: string,
    transport?: string
  ): Observable<any> {
    return this.http
      .post<AuthResponse<any>>(`${this.API_BASE}/auth/login`, {
        username,
        password,
        cfToken,
        ...(pin && {pin}),
        ...(transport && {transport}),
      })
      .pipe(
        tap((res) => {
          if (res.type === AuthResponseType.SUCCESS) {
            this.setSession(res);
          }
        }),
        share()
      );
  }

  private setSession(authRes: AuthResponse<{ accessToken: string }>) {
    localStorage.setItem(
      AuthService.TOKEN_STORAGE_KEY,
      authRes.data.accessToken
    );
  }

  logout(): Observable<void> {
    return this.http.get<void>(`${this.API_BASE}/auth/logout`).pipe(
      tap(() => {
        localStorage.removeItem(AuthService.TOKEN_STORAGE_KEY);
        this.loadedUser = undefined;
        Sentry.setUser(null);
      })
    );
  }

  public isLoggedIn() {
    return !this.isTokenExpired();
  }

  isTokenExpired() {
    const token = this.getGwtToken();

    if (!token) return true;

    try {
      const {exp: expiry} = jwtDecode(token);
      return !expiry || Math.floor(Date.now() / 1000) >= expiry;
    } catch {
      return true;
    }
  }

  getGwtToken(): string | null {
    return localStorage.getItem(AuthService.TOKEN_STORAGE_KEY);
  }

  loadUserData(): Observable<User> {
    return this.http.get<User>(`${this.API_BASE}/auth/profile`);
  }

  init(): Observable<any> {
    return this.http.get<User>(`${this.API_BASE}/auth/profile`).pipe(
      catchError((error) => {
        if (error.message === 'Unauthorized') {
          this.logout().subscribe();
        }
        throw error;
      }),
      tap((usr) => {
        this.loadedUser = usr;
        Sentry.setUser(usr);
      }),
      share()
    );
  }

  isAdmin(): boolean {
    return this.loadedUser?.roles.includes(Role.Administrator) || false;
  }

  isHr(): boolean {
    return this.loadedUser?.roles.includes(Role.hr) || false;
  }

  canEdit(userId: number): boolean {
    return this.loadedUser?.id === userId || this.isAdmin() || this.isHr();
  }

  checkMfaStatus(userId: number): Observable<MfaStatus> {
    return this.http.get<MfaStatus>(
      `${this.API_BASE}/auth/check-mfa-status/${userId}`
    );
  }

  resetMfaTries(req: ResetMfaTriesReq): Observable<void> {
    return this.http.post<void>(`${this.API_BASE}/auth/reset-mfa-tries`, req);
  }
}
