import { BaseModelWithName } from '../../shared/models/base.model';
import { ChatStatus } from '../../modules/chat/services/chat.service';
import { Organization } from '../../modules/organization/models/Organization';
import { Communication } from '../../shared/blocks/communication-select-wrapper/models/Communication';
import { Activity } from '../../modules/activity/models/Activity';

export enum Role {
  Administrator = 'administrator',
  User = 'user',
  Crm_User = 'crm_user',
  Crm_Doc = 'crm_doc',
  Crm_Sales = 'crm_sales',
  hr = 'hr',
  sms_recipient = 'sms_recipient',
}

export interface User extends BaseModelWithName {
  fullName: string;
  title: string;
  phone: string;
  ldapDepartment: string;
  email: string;
  gmail: string;
  ldapOnly: boolean;
  ask2FA: boolean;
  disabled: boolean;
  lastLogonDate: Date;
  roles: Role[];
  birthday: Date;
  personalPhone: string;
  internalPhone: string;
  city: string;
  notes: string;
  nameTransliteration: string;
  avatar: string;
  skype: string;
  twitter: string;
  facebook: string;
  linkedin: string;
  comment: string;
  gender: Gender;
  chatStatus: ChatStatus;
  webPushAllowed: boolean;
  communication?: Communication[];
  organization?: Partial<Organization>;
  currentActivity: Partial<Activity>;
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export const genderFriendlyNames = {
  [Gender.Female]: 'Жіноча',
  [Gender.Male]: 'Чоловіча',
};
